import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'
import { H2 } from '@atoms/Typography'
import { Container } from '@atoms/Grid'
import ProjectList from '@molecules/ProjectList'

const ExternalSection = ({ title, data }) => {
  return (
    <>
      <S.Container>
        <H2 as="h1">{title}</H2>
      </S.Container>
      <ProjectList data={data} />
    </>
  )
}

const S = {}

S.Container = styled(Container)`
  ${breakpoints({
    xs: css`
      margin-top: 160;
      margin-bottom: 25;
    `,
    md: css`
      margin-bottom: 65;
    `,
    xl: css`
      margin-bottom: 130;
      margin-top: 252;
    `,
  })}
`

export default ExternalSection
