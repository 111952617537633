import React from 'react'
import { TitleSection } from '@atoms/Typography'
import { Container } from '@atoms/Grid'
import CardProject from '@molecules/CardProject'
import styled from '@xstyled/styled-components'

import idgen from '../../utils/idgen'
import chunkArray from '../../utils/chunkArray'

const ProjectList = ({ sectionTitle, data, ...props }) => {
  const projectList = chunkArray(data.nodes, 10)
  const defineLayout = (index) => {
    if (index === 0 || index === 3) return 'short'
    if (index === 1 || index === 2) return 'tall'
    return 'wide'
  }

  return (
    <Container mb={{ xs: 70, md: 65 }} {...props}>
      {sectionTitle && <TitleSection>{sectionTitle}</TitleSection>}

      {projectList.map((group) => (
        <S.Content key={idgen()}>
          {group.map((item, i) => (
            <CardProject
              layout={defineLayout(i)}
              {...item.frontmatter}
              key={item.frontmatter.slug}
            />
          ))}
        </S.Content>
      ))}
    </Container>
  )
}

const S = {}

S.Content = styled.box`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export default ProjectList
