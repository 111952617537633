import React from 'react'
import { graphql } from 'gatsby'
import styled from '@xstyled/styled-components'
import { th } from '@xstyled/system'
import Layout from '@organisms/Layout'
import ExternalSection from '@organisms/Projects/ExternalSection'
import idgen from '@utils/idgen'

const Projects = ({ data }) => {
  const { blocks, meta } = data.projectsPage.nodes[0].frontmatter
  const projects = data.projects
  return (
    <Layout
      hamburgerMenu
      title={meta.title || ''}
      description={meta.description || ''}
      invertColor="true"
    >
      <S.Main>
        {blocks.map((item) => {
          switch (item.type) {
            case 'externalSection':
              return (
                <ExternalSection
                  key={idgen()}
                  title={item.title}
                  data={projects}
                />
              )
            default:
              return null
          }
        })}
      </S.Main>
    </Layout>
  )
}

const S = {}

S.Main = styled.mainBox`
  display: flex;
  flex-direction: column;
  background: ${th('colors.dark')};
  color: ${th('colors.light')};
`

export const pageQuery = graphql`
  query ProjectsPage {
    projectsPage: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "projects" } } }
    ) {
      nodes {
        frontmatter {
          ...RepeaterFragment
        }
      }
    }
    projects: allMarkdownRemark(
      filter: { frontmatter: { collection: { eq: "project" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      nodes {
        ...ProjectsFragment
      }
    }
  }
`

export default Projects

export const ProjectsFragment = graphql`
  fragment ProjectsFragment on MarkdownRemark {
    frontmatter {
      title
      excerpt
      slug
      coverImage {
        childImageSharp {
          gatsbyImageData(width: 768, layout: CONSTRAINED)
        }
      }
    }
  }
`
